<template>
  <div class="container-fluid">
    <div class="bg"></div>
    <div class="row L1">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 text-center">
            <h1>DNG Timelapse Tool</h1>
            <p>DNG延时序列自动化过渡工具</p>
            <div class="btnbox">
              <a class="btn" href="javascript:void(0);" @click="download(`https://cloud-minapp-851.cloud.ifanrusercontent.com/DNG Timelapse Tool for Windows 1.0.6.exe`)">Windows安装包</a>
              <a class="btn" href="javascript:void(0);" @click="download(`https://cloud-minapp-851.cloud.ifanrusercontent.com/DNG Timelapse Tool for MacOS 1.0.6.dmg`)">MacOS安装包</a>
              <p>
                <a href="https://pan.baidu.com/s/1n0Q0jlQjUT5RFR7NnJY8hA" target="_blank">备用下载</a> 提取码：drcm
              </p>
            </div>
            <div class="btnbox visible-xs">
              <a class="btn" href="javascript:void(0);">请前往桌面端下载</a>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="screenshot">
              <img  draggable="false" class="img-responsive" src="@/assets/img/dtt/1.jpg" alt="" srcset="" >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row L2">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="page-header">
              <h1>为什么会有DNG Timelapse Tool</h1>
            </div>
            <p>通常，在一次延时摄影中，可以拍摄获得上百张成系列的照片，并最终渲染成一段看起来是正在加速播放画面的视频文件。</p>
            <p>由于前后的画面不一，我们就需要对不同时间的照片做不同的调色处理，但是对于一个数量高达上百张的照片来说，人工处理费时费力。有了DNG Timelapse Tool，你可以像在视频编辑软件中一样，设置关键帧、对关键帧进行处理，软件将会自动计算除了关键帧以外的其他帧的调色参数。</p>
            <p>本工具通过监控关键帧在硬盘上的文件变化，来知晓用户是否对照片进行了调整，如果是，则会自动计算并更新整个序列的参数变化。</p>
            <div class="page-header">
              <h1>使用教程</h1>
            </div>
            <p>1. 启动DNG Timelapse Tool（下面简称“DTT”）</p>
            <p>2. 点击“选择文件夹”，DTT将会自动读取该文件夹下的DNG文件，并认为这批DNG文件为一个延时序列。</p>
            <p>3. DTT需要一些时间来读取每一张照片的EXIF信息和XMP信息，读取速度根据电脑性能会有所不同。通常加载一个3000张照片的序列一般不会超过5分钟。您会在素材面板查看到读取进度。读取完毕后，DTT将会根据时间排序这些照片，如果包含编号为9999和0000的照片，DTT会给出是否自动重命名的提示。</p>
            <p>4. 读取完成后将点亮左侧“关键帧处理”面板，这里您可以通过拖动下方的时间轴、缩放滑块等设置关键帧。 设置 / 取消关键帧的操作会实时保存到DNG文件，为照片打上4颗星。如果在前一步读取序列时检查到照片本身的星级为4星，那么将会自动设置为关键帧。</p>
            <p>5. 前往您熟悉的照片后期软件（如Lightroom、Adobe Camera Raw）中对关键帧（打4星的）照片进行调色，在照片后期软件保存数据到DNG文件时，DTT将自动响应这些变化，自动对整个序列的过渡参数进行计算</p>
            <p>6. 完成对所有关键帧的调色后，点击“保存序列”, DTT将会批量把过渡计算好的参数写入DNG文件中。</p>
            <p>7. 在照片后期软件中读取元数据，您将可以看到自动过渡好的调色信息。</p>
            <div class="page-header">
              <h1>其他问题</h1>
            </div>
            <div class="qa">
              <p class="q">Q: 我的照片拍摄到编号到9999，然后是0001了，该如何操作？</p>
              <p class="a">A: 出现这种情况是因为相机照片编号是4位数的，当到达9999时，相机会新建一个文件夹从0001开始计数。虽然看起来是两个序列，但他们其实应该是同一个延时序列。您可以将两段照片序列都放到同一个文件夹下，并使用DTT读取。当DTT读取到同时具有9999和0001编号的照片时，将会提示是否进行自动重命名，重命名完成后的序列，是根据拍摄时间正序的1.dng、2.dng...的序列，没有数字位数上限。</p>
            </div>
            <div class="qa">
              <p class="q">Q: 有些调色选项无效是因为什么？</p>
              <p class="a">A: 目前有部分选项是无法进行过渡计算的，因为DTT无法判断操作者的意图或是这根本不是一个数字。在这种情况下，这些选项将会被同步为和第一个关键帧相同的参数：红眼矫正、[基本面板]配置文件、[基本面板]白平衡类型、[基本面板]自动曝光开关、[色调曲线面板]自定义曲线、[镜头校正面板]配置文件相关选项、[镜头校正面板]锁定裁切选项、[校准面板]处理版本；以下选项将会被忽略：渐变滤镜、径向滤镜、污点修复</p>
            </div>
            <div class="qa">
              <p class="q">Q: 是否支持连拍的延时序列？</p>
              <p class="a">A: 理论上支持。但是有些相机在记录拍摄时间时会忽略毫秒，因此在同一秒内拍摄的多张照片，DTT会认为是同时拍摄的，此时文件排序会根据文件名称编号来进行。通常情况下不会有问题，单不排除会有意外情况，导致DTT的排序错乱。</p>
            </div>
            <div class="qa">
              <p class="q">Q: 我的相机的照片是ARW格式，或是其他格式的，能否使用DTT？</p>
              <p class="a">A: 很抱歉，不可以。请先转换成DNG格式再进入DTT进行操作。推荐使用Adobe DNG Converter、本工具作者开发的DNG自动转换工具等软件帮助你做到这一点。注意：jpg / heif / png文件是图片不是RAW，所以无法转换成DNG格式，强行转换将会造成不可预知的问题，所以前期拍摄请务必使用相机的RAW格式拍摄。</p>
            </div>
            <div class="qa">
              <p class="q">Q: 如何恢复照片文件？</p>
              <p class="a">A: DTT只会写入XMP相关的调整参数，并的确可能还存在bug导致写入的信息不正确。您可以使用Lightroom中的"复位"按钮来清除这些数据。</p>
            </div>
            <div class="qa">
              <p class="q">Q: 为什么我在Lightrrom中的调整DTT没有自动监控到？</p>
              <p class="a">A: 首先，Lightroom中必须打开自动写入功能。具体位置：编辑-目录设置-元数据-勾选“将更改自动写入XMP中”，其次，如果同时在多个软件中操作，Lightroom可能会提示“硬盘上的文件已更改，是否导入磁盘设置？”，在未导入之前，Lightroom的改动不会写入DNG文件中，DTT也不会读取到变动。</p>
            </div>
            <div class="qa">
              <p class="q">Q: 如何在Lightroom中读取到DTT保存的数据？</p>
              <p class="a">A: 方法1：在图库模块中，全选照片，右击 元数据-从文件中读取元数据。方法2：在图库模块中，左侧选择文件夹，右击 同步文件夹，勾选 扫描元数据更新。</p>
            </div>
            <div class="qa">
              <p class="q">Q: 如何设置关键帧？</p>
              <p class="a">A: 在DTT中，关键帧就是打4星的文件。星级可以在时间轴上点击按钮设置，再次点击即可删除关键帧。在首次读取序列时，4星的照片会被DTT识别为关键帧。但是在已加载后，再在其他软件中设置照片星级为4星时，DTT将不会将其识别为关键帧。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: 'RAW2DNG',
  props: {
    
  },
  computed:{

  },
  data(){
    return {
      publicPath: process.env.BASE_URL
    }
  },
  mounted(){
    
  },
  methods:{
    download(link){
      var a = document.createElement('a');
      a.href = link;//下载文件地址
      a.click(); 
    },
  }

}
</script>
<style lang="less" scoped>
	.flex{
		display: flex;
		align-items: center;
		justify-content: flex-start;	
	}
  .container-fluid{
    width: 100%;
    padding: 0;
    margin: 0;
    .bg{
      width: 100%;
      height: 800px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      background-image: linear-gradient(25deg, #cc584f, #d5784b, #dd9546, #e3b23e);
    }
    .row{
      margin: 0;
      padding: 0;
    }
    .L1{
      color: #fff;
      padding-top: 100px;
      height: 1200px;
      h1{
        font-size: 64px;
        font-weight: 300;
        margin-top: 70px;
        @media (max-width: 970px) { 
          margin: 100px 0 50px;
        }
      }
      p{
        font-weight: 300;
      }
      .btnbox{
        text-align: center;
        margin: 60px 0;
        .btn{
          font-size: 16px;
          line-height: 1;
          padding: 8px 20px 8px;
          border: 1px solid #fff;
          color: #fff;
          border-radius: 20px;
          margin: 10px 0;
          display: inline-block;
          margin-right: 10px;
          &:hover{
            color: #e3b23e;
            background-color: #fff;
          }
        }
        a{
          color: #fff;
        }
      }
      .screenshot{
        width: 100%;
        overflow: hidden;
        text-align: center;
        padding-bottom: 50px;
        img{
          width: 80%;
          border-radius: 10px;
          display: inline-block;
          box-shadow: 0 0 30px rgba(0,0,0,0.2);
        }
      }
    }
    .L2{
      p{
        line-height: 1.75;
        margin-bottom: 1.2em;
      }
    }
    .qa{
      .q{
        margin-bottom: 0px;
      }
      .a{
        text-indent: -1.3em;
        margin-left: 1.3em;
      }
    }
    @media screen and (max-width:900px){
      .L1{
        height: 800px;
        .btnbox{
          display: none;
        }
      }
      .L2{
        .panel{
          .panel-inbox{
            padding: 10px 20px;
          }
          h4{
            font-size: 28px;
          }
        }
      }
    }
  }
</style>